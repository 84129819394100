import { gql } from "@apollo/client";
import { ASSET_REPORT_LIST_POLICIES_FIELDS } from "../fragments/AssetReportListPoliciesFields";
import { ASSET_REPORT_CHECKS_FIELDS } from "../fragments/AssetReportChecksFields";

export const LOAD_ASSET_REPORT = gql`
  query LoadAssetReport(
    $mrn: String!
    $assetLinkInput: AssetLinkInfoInput!
    $assetReportInput: AssetReportInput!
    $listPoliciesOrderBy: AssetReportPolicyOrder
    $listPoliciesFilter: AssetReportListPoliciesFilter
    $listPoliciesAfter: String
    $listPoliciesFirst: Int
    $policiesOrderBy: AssetReportPolicyOrder
    $policiesPolicyFilter: [String]
    $policiesAfter: String
    $policiesFirst: Int
    $recommendedFirst: Int
    $recommendedAfter: String
    $checksFirst: Int
    $checksAfter: String
    $checksPolicyFilter: [String]
    $checksOrderBy: AssetReportQueryOrder
    $checksFilter: String
    $checksImpact: [String!]
    $checksPolicyMrns: [String!]
  ) {
    getAssetLinkInfo(input: $assetLinkInput) {
      ... on FleetAssetLinkInfo {
        mrn
        assetLinkType
      }
      ... on CiAssetLinkInfo {
        mrn
        assetLinkType
        projectID
      }
    }
    asset(mrn: $mrn) {
      id
      mrn
      name
      state
      updatedAt
      labels {
        key
        value
      }
      annotations {
        key
        value
      }
      score {
        grade
        value
        type
        completion
        message
        weight
      }
      relatedAssets {
        count {
          isParent
          count
          type
        }
      }
      report {
        stats {
          packages {
            total
          }
        }
      }
      platform {
        name
        release
        arch
        kind
        runtime
        title
      }
      referenceIDs
      asset_type
    }
    assetReport(input: $assetReportInput) {
      ... on NotFoundError {
        code
        message
      }
      ... on AssetReport {
        lastCompletedScoreTime
        overviewGroups {
          items {
            key
            name
            sources {
              ... on KeyValueSource {
                key
                value
              }
              ... on QuerySource {
                mrn
              }
            }
            values {
              ... on AssetOverviewStringValue {
                stringValue: value
              }
              ... on AssetOverviewNullValue {
                nullValue: value
              }
              ... on AssetOverviewTimeValue {
                timeValue: value
              }
              ... on AssetOverviewArrayValue {
                arrayValue: value {
                  ... on AssetOverviewStringValue {
                    stringValue: value
                  }
                  ... on AssetOverviewNullValue {
                    nullValue: value
                  }
                  ... on AssetOverviewTimeValue {
                    timeValue: value
                  }
                  ... on AssetOverviewDictValue {
                    dictValue: value {
                      key
                      value
                    }
                  }
                  ... on AssetOverviewBoolValue {
                    boolValue: value
                  }
                  ... on AssetOverviewReferenceValue {
                    displayValue
                    mrn
                    referenceType
                  }
                  ... on AssetOverviewError {
                    value {
                      ... on AssetOverviewErrorExecutionFailed {
                        msg
                        queryMrn
                      }
                      ... on AssetOverviewGenericError {
                        code
                        msg
                      }
                    }
                  }
                  ... on AssetOverviewURLValue {
                    displayValue
                    url
                  }
                }
              }
              ... on AssetOverviewDictValue {
                dictValue: value {
                  key
                  value
                }
              }
              ... on AssetOverviewBoolValue {
                boolValue: value
              }
              ... on AssetOverviewReferenceValue {
                displayValue
                mrn
                referenceType
              }
              ... on AssetOverviewError {
                value {
                  ... on AssetOverviewErrorExecutionFailed {
                    msg
                    queryMrn
                  }
                  ... on AssetOverviewGenericError {
                    code
                    msg
                  }
                }
              }
              ... on AssetOverviewURLValue {
                displayValue
                url
              }
            }
          }
          key
          title
        }
        analysis {
          eol {
            status
            date
          }
        }
        score {
          grade
          value
          type
          completion
          message
          weight
        }
        listPolicies(
          first: $listPoliciesFirst
          after: $listPoliciesAfter
          filter: $listPoliciesFilter
          orderBy: $listPoliciesOrderBy
        ) {
          ...AssetReportListPoliciesFields
        }
        policies(
          orderBy: $policiesOrderBy
          policyFilter: $policiesPolicyFilter
          after: $policiesAfter
          first: $policiesFirst
        ) {
          edges {
            node {
              policy {
                name
                mrn
                action
                assigned
              }
              score {
                grade
                value
                type
                completion
                message
                weight
              }
              queries {
                id
                mquery {
                  uid
                  mrn
                  title
                  severity
                  action
                  code
                  mql
                  docs {
                    desc
                    audit
                    remediation
                  }
                }
                mqueryState
                mqueryType
                action
                assessment {
                  state
                  rendered
                  results {
                    actual
                    expected
                    error
                  }
                  needsUpgrade
                  rendered
                }
                score {
                  value
                  grade
                  type
                  completion
                  message
                  weight
                }
                data
                policies
              }
              scoreStats {
                total
                passed
                failed
                incomplete
                errors
                skipped
                unknown
                worst
                snoozed
                disabled
              }
            }
            cursor
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
            endCursor
            startCursor
          }
          totalCount
        }
        checks(
          first: $checksFirst
          after: $checksAfter
          policyFilter: $checksPolicyFilter
          orderBy: $checksOrderBy
          filter: $checksFilter
          impact: $checksImpact
          policyMrns: $checksPolicyMrns
        ) {
          ...AssetReportChecksFields
        }
        recommendedActions(first: $recommendedFirst, after: $recommendedAfter) {
          totalCount
          edges {
            cursor
            node {
              ... on RecommendedActionItemAdvisory {
                id
                message
                severity
              }
              ... on RecommendedActionItemControl {
                mrn
                codeId
                message
                severity
                score {
                  grade
                  value
                  type
                  completion
                  message
                  weight
                }
              }
            }
          }
        }
        insights {
          security {
            policyCount
            score {
              id
              value
              type
              completion
              weight
              message
              grade
            }
          }
          vulnerability {
            score {
              id
              value
              type
              completion
              weight
              message
              grade
            }
          }
        }
      }
    }
  }
  ${ASSET_REPORT_LIST_POLICIES_FIELDS}
  ${ASSET_REPORT_CHECKS_FIELDS}
`;
